import Link from 'next/link';
import React from 'react';

const News_item = ({
	data,
	classes = 'grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2',
}) => {
	return (
		<>
			<div className={classes} style={{ minHeight: '400px', height: '100%' }}>
				{data.map((item) => {
					const { id, image, title, text, date, time, plan } = item;
					const link = image.split('/').slice(3).toString().replace('.jpg', '');
					return (
						<article key={id} style={{ minHeight: '400px', height: '100%' }}
						>
							<div className="rounded-2xl overflow-hidden transition-shadow hover:shadow-lg group dark:bg-jacarta-900 bg-jacarta-900 rounded-b-[1.25rem]" style={{ minHeight: '400px', height: '100%' }}>
								<figure className="overflow-hidden ">

									<img
										src={image}
										alt={title}
										className="h-full w-full object-cover transition-transform ease-out duration-[500ms] will-change-transform group-hover:scale-105 "
									/>

								</figure>

								{/* <!-- Body --> */}
								<div className="dark:bg-jacarta-900 bg-jacarta-900 rounded-b-[1.25rem] p-[10%]">
									{/* <!-- Meta --> */}
									<div className="mb-3 flex flex-wrap items-center space-x-1 text-xs">

										<span className="dark:text-jacarta-400 text-jacarta-400 font-bold">{plan}</span>

									</div>

									<h2 className="font-display text-white mb-4 text-xl dark:text-white">

										{title}

									</h2>
									<p className="dark:text-jacarta-200 text-jacarta-200 mb-8">{text}</p>

									<Link href="/contacto">
										<a className="dark:text-accent text-accent font-display dark:hover:text-accent-dark hover:text-accent-dark ease-out duration-200">
											CONTACTAR UN ASESOR
										</a>
									</Link>
								</div>
							</div>
						</article>
					);
				})}
			</div>
		</>
	);
};

export default News_item;
