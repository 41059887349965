/* eslint-disable react/no-unescaped-entities */
import Image from 'next/image';
import React from 'react';
import { case_studies_data } from '../../data/case-studies_data';
import Partners2 from '../partners/Partners2';
import Irbin from '../3js/Irbin';

const Post = () => {
	return (
		<div>
			{/* <!-- Post --> */}
			<section className="relative py-16 md:py-24"
			style={{
				backgroundColor:'#060709'
			}}
			>
				{case_studies_data
					.map((item) => {
						console.log(item.href);
						const { id, img, title } = item;
						return (
							<div key={id}>
								<div className='container'>
									<header className="mx-auto mb-16 grid grid-cols-12 gap-4 mt-24">
										<div className="col-span-10 md:col-span-2 self-center">
											<Image src={"/images/case-studies/irbin-logo.png"} width={172} height={87}></Image>
										</div>

										<div className="col-span-10 md:col-span-10 self-center">
											<div className="flex-column">
												<h1 className="text-xl dark:text-white text-white text-left md:text-right">
													{title}
												</h1>
												<p className='dark:text-jacarta-200 text-jacarta-200 text-left md:text-right'>Un proyecto CIRSYS</p>
											</div>
										</div>
									</header>
								</div>

								{/* <!-- Featured image --> */}
								{/* <figure className="mb-4">
									<img
										src={"/images/case-studies/irbin-demo.png"}
										alt={title}
										className="w-full rounded-2.5xl max-h-[70vh] object-cover"
									/>
								</figure> */}

								{/* <!-- Irbin --> */}
								<Irbin />

								{/* <!-- Article --> */}
								<div className='container'>
								<article className='mt-10'>
									<div className="lg:flex lg:space-x-8">
										<div className="mt-12 lg:w-1/3">
											<div className="mb-7">
												<h3 className="font-display text-md font-semibold dark:text-white text-white">
													Objetivo
												</h3>
												<span className="text-2xs font-medium dark:text-jacarta-300 text-jacarta-300">
													Reciclaje
												</span>
											</div>
											<div className="mb-7">
												<h3 className="font-display text-md font-semibold text-white dark:text-white">
													Interino
												</h3>
												<span className="text-2xs font-medium dark:text-jacarta-300 text-jacarta-300">
													Desarrollo de Hardware y Software.
												</span>
											</div>
											<div className="mb-7">
												<h3 className="font-display text-md font-semibold text-white dark:text-white">
													Lugar
												</h3>
												<span className="text-2xs font-medium dark:text-jacarta-300 text-jacarta-300">
													Lima, Peru
												</span>
											</div>
										</div>
										{/* <!-- Content --> */}
										<div className="article-content lg:w-2/3">
											<h2 className="text-white dark:text-white text-3xl">Acerca de IRBin</h2>
											<p className='dark:text-jacarta-300 text-jacarta-300'>
												IRBin es el primer robot social con inteligencia artificial para el reciclaje en el Perú. IRBin no sólo segrega los residuos; sino que gracias a su personalidad, se relaciona con el usuario, le brinda una experiencia interactiva y memorable, que genera el aprendizaje y formación de hábitos en relación al reciclaje y al consumo de materiales inorgánicos.
											</p>
											<p className='dark:text-jacarta-300 text-jacarta-300'>
												Asimismo, IRBin aprende del usuario en cada interacción, pudiendo identificar con mayor facilidad nuevos tipos de residuos, y generando data valiosa que nos permita continuar fomentando el reciclaje de materiales reciclables en hogares y espacios públicos.
											</p>
											<p className='dark:text-jacarta-300 text-jacarta-300'>
												Finalmente, el sistema de IRBin permite al usuario obtener beneficios gracias al reciclaje. De esta manera fomenta el hábito de reciclaje para así formar una comunidad más responsable y sostenible.
											</p>
											<div className="mb-16 flex items-center">

												<span className="dark:text-jacarta-300 text-jacarta-300 mr-4 text-sm font-bold">Sigue a IRBin:</span>
												<div className="flex space-x-2">
													<a
														target="_blank"
														rel="noopener noreferrer"
														className="group cursor-pointer"
														href='https://www.instagram.com/irbin.pe'
													>
														<svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-accent ease-out duration-200">
															<use xlinkHref={`/icons.svg#icon-instagram`}></use>
														</svg>
													</a>
												</div>
											</div>

										</div>


										{/* <!-- Share --> */}

									</div>

									<Partners2 />
								</article>
								</div>
							</div>
						);
					})}
			</section>
			{/* <!-- end post --> */}
		</div>
	);
};

export default Post;
