import { useRouter } from "next/router";
import pathChecking from "../../utils/pathChecking";
import Testimonial_carousel from "../carousel/testimonial_carousel";

const Testimonial2 = ({ bg_jacerta }) => {
	const route = useRouter();

	return (
		<div className="container mt-14 mb-36">
			<div className="dark:bg-jacarta-700 rounded-2.5xl flex flex-wrap bg-white p-10 md:flex-nowrap md:space-x-8 md:p-[4.25rem] lg:space-x-16">
				<div className="bg-accent mb-8 flex h-16 w-16 shrink-0 items-center justify-center rounded-full md:mb-0 md:w-16">
					<svg
						width="22"
						height="19"
						fill="black"
						xmlns="http://www.w3.org/2000/svg"
						className="fill-black"
					>
						<path d="M6.027 18.096c-.997 0-1.813-.204-2.448-.612a5.147 5.147 0 01-1.564-1.564 5.729 5.729 0 01-.952-2.38C.927 12.679.86 11.976.86 11.432c0-2.221.567-4.239 1.7-6.052C3.693 3.567 5.461 2.093 7.863.96l.612 1.224c-1.405.59-2.606 1.519-3.604 2.788-1.042 1.27-1.564 2.561-1.564 3.876 0 .544.068 1.02.204 1.428a3.874 3.874 0 012.516-.884c1.179 0 2.199.385 3.06 1.156.862.77 1.292 1.836 1.292 3.196 0 1.27-.43 2.312-1.292 3.128-.861.816-1.881 1.224-3.06 1.224zm11.56 0c-.997 0-1.813-.204-2.448-.612a5.148 5.148 0 01-1.564-1.564 5.73 5.73 0 01-.952-2.38c-.136-.861-.204-1.564-.204-2.108 0-2.221.567-4.239 1.7-6.052 1.134-1.813 2.902-3.287 5.304-4.42l.612 1.224c-1.405.59-2.606 1.519-3.604 2.788-1.042 1.27-1.564 2.561-1.564 3.876 0 .544.068 1.02.204 1.428a3.874 3.874 0 012.516-.884c1.179 0 2.199.385 3.06 1.156.862.77 1.292 1.836 1.292 3.196 0 1.27-.43 2.312-1.292 3.128-.861.816-1.881 1.224-3.06 1.224z" />
					</svg>
				</div>

				<div className="mb-4 md:mb-0">
					<p className="text-jacarta-700 text-xl font-display dark:text-white">
						The capacity and scalability of IRBin assure us that it can be an importante part of the recycle culture that Peru demands.
					</p>
					<span className="text-jacarta-700 font-display text-md mt-12 block font-medium dark:text-white">
						Gunther Merzthal
					</span>
					<span className="dark:text-jacarta-400 text-2xs font-medium tracking-tight">
						General Education Director, Ministry of Environment
					</span>
				</div>

				<img
					src="/images/testimonials/gunther.png"
					alt="Testimonials"
					className="rounded-2.5xl w-28 self-start lg:w-auto"
				/>
			</div>
		</div>
	);
};

export default Testimonial2;
