import Link from "next/link";

const Partners2 = () => {
  const partnerGroup = [
    { id: 1, logo: "cliente-7" },
    { id: 2, logo: "cliente-5" },
    { id: 3, logo: "cliente-4" },
    { id: 4, logo: "cliente-6" },
    { id: 5, logo: "cliente-8" },
    { id: 6, logo: "cliente-9" },
    { id: 7, logo: "cliente-10" },
    { id: 8, logo: "cliente-11" },
  ];
  return (
    <>
      <div className="bg-jacarta-700 dark:bg-jacarta-700 "
        style={{
          backgroundColor: '#060709'
        }}
      >
        <div className="container">
          <h1 className="font-display text-3xl text-center mt-20 mb-10 dark:text-white text-white">Empresas que trabajan con IRBin</h1>
          <div className="grid grid-cols-2 pt-8 md:grid-cols-4">
            {partnerGroup.map((item) => (
              <div key={item.id}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center'
                }}
              >
                <img
                  src={`/images/partners/${item.logo}.png`}
                  alt="partner 1"
                  className="self-center"
                  style={{
                    filter: 'grayscale(100%) brightness(5)',
                    width: 'auto',
                    maxWidth: '150px',
                    height: 'auto',
                    maxHeight: '60px',
                    marginBottom: '50px',
                    padding:'0px 10px'
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners2;
