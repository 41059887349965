import * as THREE from 'three'
import React, { useRef, useState, Suspense, useEffect } from "react";
import { Canvas, extend, useFrame, useLoader, useThree } from '@react-three/fiber'
import { RGBELoader } from 'three-stdlib'
import { useGLTF, PresentationControls, Environment, Html, Text, MeshReflectorMaterial, Lightformer, Sky, OrbitControls, Loader, PerformanceMonitor, AdaptiveDpr } from '@react-three/drei'
import { Info, Maximize2, CheckCircle, Move, Radio, Wifi, Activity, X } from 'react-feather';
import { animated, SpringValue, useSpring, useTransition } from "@react-spring/web"
import { a, config } from "@react-spring/three"
import Image from 'next/image';
import Media from 'react-media';

//icons
import Bin from '../../public/images/bin.svg'
import Trash from '../../public/images/trash.svg'
import App from '../../public/images/app-notification.svg'
import Charging from '../../public/images/charging.svg'


function Model(props) {
  const { nodes, materials } = useGLTF("/irbin7-transformed.glb");

  const [video] = useState(() => Object.assign(document.createElement('video'), { src: '/eyeloop-v3.mp4', crossOrigin: 'Anonymous', loop: true, muted: true, autoplay: true }))
  useEffect(() => {
    void video.play()
  }, [video])

  return (
    <a.group {...props} dispose={null} scale={0.028} className='model-group'>
      {props.children}
      <mesh
        geometry={nodes["eyes-screen"].geometry}
      >
        <meshBasicMaterial toneMapped={false} >
          <videoTexture flipY='true' attach="map" args={[video]} encoding={THREE.sRGBEncoding} />
        </meshBasicMaterial>
      </mesh>
      <mesh
        geometry={nodes["top-lights"].geometry}
        material={materials.LightEmissive}
      />
      <mesh
        geometry={nodes.colector.geometry}
        material={materials.BlackPlastic}
      />
      <mesh
        geometry={nodes["collector-screen"].geometry}
        material={materials.CollectorScreen}
      />
      <mesh
        geometry={nodes.glass.geometry}
        material={materials.Glass}
      />
      <mesh
        geometry={nodes.black.geometry}
        material={materials.BlackPlastic}
      />
      <mesh
        geometry={nodes.base.geometry}
        material={materials.GreenPlastic}
      />
      <mesh
        geometry={nodes.top.geometry}
        material={materials.WhitePlastic}
      />
    </a.group>
  );
}

function FloorModel(props) {
  const { nodes, materials } = useGLTF("/ground3.glb");

  return (
    <a.group {...props} dispose={null} scale={0.028} className='model-group'>
      {props.children}
      <mesh
        geometry={nodes.ground.geometry}
        material={materials.groundMaterial}
      />
    </a.group>
  );
}

function EnvMap({ }) {
  const texture = useLoader(RGBELoader, 'venice_sunset_2k.hdr')

  return (
    <Environment>
      <mesh rotation={[0, -0.42, 0]} scale={100}>
        <sphereGeometry />
        <meshBasicMaterial map={texture} side={THREE.BackSide} toneMapped={false} />
      </mesh>
      <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[4, 3, 2]} scale={[5, 5, 1]} />
    </Environment>
  )
}

function Rig({ children }) {
  const ref = useRef()
  const vec = new THREE.Vector3()
  const { mouse } = useThree()

  useFrame((state) => {
    ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (mouse.x * Math.PI) * 0.05, 0.1)
    ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, (mouse.y * Math.PI) * -0.02, 0.1)

    // state.camera.position.lerp({ x: mouse.x / 2, y: mouse.y / 4, z: 5 }, 0.1)
    // state.camera.lookAt(-1, 0, 0)

    // ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (mouse.x * Math.PI) * 0.1, 0.1)
    // ref.current.rotation.z = THREE.MathUtils.lerp(ref.current.rotation.x, (mouse.y * Math.PI) * 0.05, 0.1)
    // ref.current.rotation.x = THREE.MathUtils.lerp(ref.current.rotation.x, (mouse.y * Math.PI) * -0.05, 0.1)
  })

  return (
    <a.group
      ref={ref}
      zIndexRange={[20, 0]}
    >
      {children}
    </a.group>
  )
}

export default function Irbin({ route, ...props }) {

  const [popup, setPopup] = useState(false);
  const [funciona, setFunciona] = useState(false)
  const [informacion, setinformacion] = useState(false)
  const [objetivo, setObjetivo] = useState(false)
  const [info, setInfo] = useState(true)
  const [dpr, setDpr] = useState(1.5)

  //Spring Animations
  const [index, setIndex] = useState(0)
  const rotations = [
    [0, Math.PI * 0.145, 0],
    [0, Math.PI * 0.145, 0],
    [0, Math.PI * 2, 0],
    [0, Math.PI * 1.9, 0],
    [0, Math.PI * -1.82, 0],
  ]

  const cameraPositions = [
    [0, 0, -15],
    [0, 0, 0],
    [0, 1.2, 2.2],
    [0.8, -0.8, 1.9],
    [-1, -1, 2.2]
  ]

  const cameraRotations = [
    [Math.PI * 0.8, 0, 0],
    [0, 0, 0],
    [Math.PI * 0.1, 0, 0],
    [0, 0, 0],
    [0, 0, 0]
  ]

  const {
    rotation,
    cameraPosition,
    cameraRotation
  } = useSpring({
    rotation: rotations[index],
    cameraPosition: cameraPositions[index],
    cameraRotation: cameraRotations[index],
    config: config.slow,
  });

  const { popupHeight } = useSpring({
    popupHeight: popup ? '45%' : '0%',
    config: config.slow,
  })

  const { textOpacity } = useSpring({
    textOpacity: popup ? 1 : 0,
    config: config.gentle,
    delay: 300
  })

  //Mobile
  const { popupHeightMobile } = useSpring({
    popupHeightMobile: popup ? '60%' : '0%',
    config: config.slow,
  })

  const rotationsMobile = [
    [0, Math.PI * 0.145, 0],
    [0, Math.PI * 0.145, 0],
    [0, Math.PI * 1.9, 0],
    [0, Math.PI * 2, 0],
    [0, Math.PI * -1.85, 0],
  ]

  const cameraPositionsMobile = [
    [0, 0, -15],
    [0, 0, 0],
    [0.2, 0.2, 1.8],
    [0, 0, 2],
    [-0.2, -0.3, 2.5],
  ]

  const cameraRotationsMobile = [
    [Math.PI * 0.8, 0, 0],
    [0, 0, 0],
    [Math.PI * 0.1, 0, 0],
    [0, 0, 0],
    [0, 0, 0]
  ]

  const {
    rotationMobile,
    cameraPositionMobile,
    cameraRotationMobile
  } = useSpring({
    rotationMobile: rotationsMobile[index],
    cameraPositionMobile: cameraPositionsMobile[index],
    cameraRotationMobile: cameraRotationsMobile[index],
    config: config.slow,
  });

  //Handlers
  const funcionaHandler = () => {
    setPopup(true)
    setFunciona(true)
    setinformacion(false)
    setObjetivo(false)
    setInfo(false)
    // setUi(false)
    setIndex(2)
  }

  const informacionHandler = () => {
    setPopup(true)
    setFunciona(false)
    setinformacion(true)
    setObjetivo(false)
    setInfo(false)
    // setUi(false)
    setIndex(3)
  }

  const objetivoHandler = () => {
    setPopup(true)
    setFunciona(false)
    setinformacion(false)
    setObjetivo(true)
    setInfo(false)
    // setUi(false)
    setIndex(4)
  }

  const closeHandler = () => {
    setPopup(false)
    setFunciona(false)
    setinformacion(false)
    setObjetivo(false)
    setInfo(true)
    // setUi(true)
    setIndex(1)
  }

  const hoverHandler = () => {
    setHover(true)
    alert('hover')
  }

  const introHandler = () => {
    setIndex(1)
    console.log(index)
  }

  const [pill, setPill] = useState('')

  useEffect(() => {
    console.log(index)
    introHandler()
    setPill("active")
  }, [])


  return (
    <div
      style={{
        height: '70vh',
        minHeight: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        position: 'relative',
        width: '100%',
        touchAction: 'pan-y',
      }}
    // ref={compHomeRef}
    >
      <Suspense fallback={<Loader
        innerStyles={{ backgroundColor: '#060709' }}
        containerStyles={{ backgroundColor: '#060709' }}
      />}>
        <Canvas
          frameloop='always'
          camera={{ fov: 75, near: 1, far: 15, position: [0, 0, 5] }}
          style={{ width: '100%', height: '100%', background: '#060709', }}
          className='canvas'
          dpr={dpr}
          eventPrefix="client"
        >
          <PerformanceMonitor onIncline={() => setDpr(2)} onDecline={() => setDpr(1)}>
            {/* <Perf position='top-left' /> */}
            <Media queries={{ small: "(max-width: 639px)" }}>
              {matches =>
                matches.small ?
                  (
                    <>
                      <a.group position={cameraPositionMobile} rotation={cameraRotationMobile} scale={0.68}>
                        <PresentationControls
                          enabled={popup ? false : true} // the controls can be disabled by setting this to false
                          global={true} // Spin globally or by dragging the model
                          cursor={true} // Whether to toggle cursor style on drag
                          snap={true} // Snap-back to center (can also be a spring config)
                          speed={1.1} // Speed factor
                          zoom={1} // Zoom factor when half the polar-max is reached
                          rotation={[0, 0, 0]} // Default rotation
                          polar={[0, Math.PI / 2]} // Vertical limits
                          azimuth={[-Infinity, Infinity]} // Horizontal limits
                          config={{ mass: 0.5, tension: 200, friction: 23 }} // Spring config
                        >
                          <Model position={[0, -2.2, 0]} rotation={rotationMobile}>
                            {info && (
                              <Html center position={[-75, 115, 50]} className='pill' zIndexRange={[19, 0]}>
                                <button
                                  onClick={funcionaHandler}
                                  className="pill-3d-button"
                                >
                                  <div
                                    className={` pill-3d ${pill}`}
                                    style={{
                                      // backgroundColor: 'red',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}>
                                    <div
                                      style={{
                                        height: '32px',
                                        width: '32px',
                                        background: '#ffffff55',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                      }}
                                    >
                                      <Info size={15} color='white' />
                                    </div>
                                  </div>
                                </button>
                              </Html>
                            )}

                            {info && (
                              <Html center position={[55, 90, 50]} zIndexRange={[19, 0]}>
                                <button
                                  onClick={informacionHandler}
                                  className="pill-3d-button"
                                >
                                  <div
                                    className={` pill-3d ${pill}`}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <div
                                      style={{
                                        height: '32px',
                                        width: '32px',
                                        background: '#ffffff55',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                      }}
                                    >
                                      <Maximize2 size={15} color='white' />
                                    </div>
                                  </div>
                                </button>
                              </Html>
                            )}

                            {info && (
                              <Html center position={[-80, 25, -40]} zIndexRange={[19, 0]}>
                                <button
                                  onClick={objetivoHandler}
                                  className="pill-3d-button"
                                >
                                  <div
                                    className={` pill-3d ${pill}`}
                                    style={{
                                      // backgroundColor: 'red',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                    <div
                                      style={{
                                        height: '32px',
                                        width: '32px',
                                        background: '#ffffff55',
                                        borderRadius: '50%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: "center",
                                      }}
                                    >
                                      <CheckCircle size={15} color='white' />
                                    </div>
                                  </div>
                                </button>
                              </Html>
                            )}
                          </Model>
                          <FloorModel position={[0, -2.2, 0]} rotation={rotationMobile} />
                        </PresentationControls>
                      </a.group>
                      <fog attach="fog" args={['#060709', 3, 10]} />
                    </>
                  ) :
                  (
                    <>
                      <a.group position={cameraPosition} rotation={cameraRotation}>
                        <PresentationControls
                          enabled={popup ? false : true} // the controls can be disabled by setting this to false
                          global={true} // Spin globally or by dragging the model
                          cursor={true} // Whether to toggle cursor style on drag
                          snap={true} // Snap-back to center (can also be a spring config)
                          speed={1.1} // Speed factor
                          zoom={1} // Zoom factor when half the polar-max is reached
                          rotation={[0, 0, 0]} // Default rotation
                          polar={[0, Math.PI / 2]} // Vertical limits
                          azimuth={[-Infinity, Infinity]} // Horizontal limits
                          config={{ mass: 2, tension: 240, friction: 23 }} // Spring config
                        >
                          <Rig>
                            <Model position={[0, -2.2, 0]} rotation={rotation}>
                              {info && (
                                // <Html center position={[-75, 115, 50]} className='pill' zIndexRange={[19, 0]}>
                                //   <button
                                //     onClick={funcionaHandler}>
                                //     <div
                                //       className={` pill-3d ${pill}`}
                                //     >
                                //       <p className='text-xs mr-4 dark:text-jacarta-200 text-jacarta-200'>
                                //         ¿CÓMO FUNCIONA?
                                //       </p>
                                //       <div
                                //         className='teste-pill'
                                //         style={{
                                //           height: '32px',
                                //           width: '32px',
                                //           background: '#ffffff55',
                                //           borderRadius: '50%',
                                //           display: 'flex',
                                //           justifyContent: 'center',
                                //           alignItems: "center",
                                //         }}
                                //       >
                                //         <Info size={16} color='white' />
                                //       </div>
                                //     </div>
                                //   </button>
                                // </Html>
                                <Html center position={[-75, 115, 50]} zIndexRange={[19, 0]}>
                                  <button
                                    onClick={funcionaHandler}
                                  >
                                    <div
                                      className='pill-3d'
                                      style={{
                                        width: '200px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                      <div
                                        className='mr-4 teste-pill'
                                        style={{
                                          height: '32px',
                                          width: '32px',
                                          background: '#ffffff55',
                                          borderRadius: '50%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: "center",
                                        }}
                                      >
                                        <Info size={16} color='white' />
                                      </div>
                                      <p className='text-xs mr-4 dark:text-jacarta-200 text-jacarta-200'>
                                        ¿CÓMO FUNCIONA?
                                      </p>
                                    </div>
                                  </button>
                                </Html>
                              )}

                              {info && (
                                <Html center position={[80, 90, 50]} zIndexRange={[19, 0]}>
                                  <button
                                    onClick={informacionHandler}
                                  >
                                    <div
                                      className='pill-3d'
                                      style={{
                                        width: '200px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                      <div
                                        className='mr-4 teste-pill'
                                        style={{
                                          height: '32px',
                                          width: '32px',
                                          background: '#ffffff55',
                                          borderRadius: '50%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: "center",
                                        }}
                                      >
                                        <Maximize2 size={16} color='white' />
                                      </div>
                                      <p className='text-xs mr-4 dark:text-jacarta-200 text-jacarta-200'>INFORMACIÓN</p>
                                    </div>
                                  </button>
                                </Html>
                              )}

                              {info && (
                                <Html center position={[-80, 25, -40]} zIndexRange={[19, 0]}>
                                  <button
                                    onClick={objetivoHandler}
                                  >
                                    <div
                                      className='pill-3d'
                                      style={{
                                        // backgroundColor: 'red',
                                        width: '200px',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                      }}>
                                      <p className='text-xs mr-4 dark:text-jacarta-200 text-jacarta-200'>OBJETIVO</p>
                                      <div
                                        className='teste-pill'
                                        style={{
                                          height: '32px',
                                          width: '32px',
                                          background: '#ffffff55',
                                          borderRadius: '50%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: "center",
                                        }}
                                      >
                                        <CheckCircle size={16} color='white' />
                                      </div>
                                    </div>
                                  </button>
                                </Html>
                              )}
                            </Model>
                            <FloorModel position={[0, -2.2, 0]} rotation={rotation} />
                          </Rig>
                        </PresentationControls>
                      </a.group>
                      <fog attach="fog" args={['#060709', 3, 10]} />
                    </>
                  )}
            </Media>
            <EnvMap />
          </PerformanceMonitor>
          {/* <AdaptiveDpr pixelated /> */}
        </Canvas>
      </Suspense>

      <Media queries={{ small: "(max-width: 639px)" }}>
        {matches =>
          matches.small ? (
            <>
              {popup && (
                <button
                  style={{
                    // position: 'absolute',
                    // right: '40px',
                    // top: '40px',
                    zIndex: '10'
                  }}
                  onClick={closeHandler}
                  className='pill-3d mobile'
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <div
                      className='teste-pill mobile'
                      style={{
                        height: '40px',
                        width: '40px',
                        background: '#ffffff55',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: "center",
                        color: 'white'
                      }}
                    >
                      <X />
                    </div>
                  </div>
                </button>
              )}
            </>
          )
            :
            (
              <>
                {popup && (
                  <button
                    style={{
                      zIndex: '10'
                    }}
                    onClick={closeHandler}
                    className='pill-3d desktop'
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <p className='pr-5 dark:text-jacarta-200 text-jacarta-200 '>CERRAR</p>
                      <div
                        className='teste-pill'
                        style={{
                          height: '40px',
                          width: '40px',
                          background: '#ffffff55',
                          borderRadius: '50%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: "center",
                          color: 'white'
                        }}
                      >
                        <X />
                      </div>
                    </div>
                  </button>
                )}
              </>
            )
        }
      </Media>

      <Media queries={{ small: "(max-width: 639px)" }}>
        {matches =>
          matches.small ?
            (
              <>
                {popup && (
                  <div
                    style={{
                      height: '100px',
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      bottom: '60%',
                    }}
                    onClick={closeHandler}
                  >
                  </div>
                )
                }
                <animated.div style={{
                  width: '100%',
                  height: popupHeightMobile,
                  background: "rgba(202, 217, 255, 0.15)",
                  position: 'absolute',
                  left: '0px',
                  bottom: '0px',
                  marginLeft: "auto",
                  marginRight: "auto",
                  backdropFilter: 'blur(20px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textShadow: '0px 3px 15px #212224',
                  borderTop: popup ? '2px solid rgba(202, 217, 255, 0.2' : (null)
                }}>
                  {/* como funciona */}
                  {funciona && (
                    <animated.div
                      className="font-display"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        opacity: textOpacity,
                        width: '100%',
                        padding: '20px 0px'
                      }}
                    >
                      <h1
                        className='text-xl font-semibold mb-5 text-white dark:text-white'
                        style={{
                          textShadow: '0px 3px 15px #212224'
                        }}
                      >
                        ¿Cómo funciona?
                      </h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '50%',
                          justifyContent: 'space-around',
                          textAlign: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <p className='mb-3 text-white dark:text-white' style={{
                          textShadow: '0px 3px 15px #212224',
                          minWidth: '50%',
                        }}>
                          1. Recepciona los residuos
                        </p>
                        <p className='mb-3 text-white dark:text-white' style={{
                          minWidth: '50%'
                        }}>
                          2. Reconocimento con IA
                        </p>
                        <p className='mb-3 text-white dark:text-white' style={{
                          minWidth: '50%'
                        }}>
                          3. Segrega por tipo de residuo
                        </p>
                        <p className='mb-3 text-white dark:text-white' style={{
                          minWidth: '50%'
                        }}>
                          4. Control del nivel de llenado
                        </p>
                      </div>
                    </animated.div>
                  )}

                  {/* informacion */}
                  {informacion && (
                    <animated.div
                      className="font-display"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor:'red',
                        height: '100%',
                        opacity: textOpacity,
                        width: '100%'
                      }}
                    >
                      <h1
                        className='text-xl font-semibold mb-5 text-white dark:text-white'
                      >Información</h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          justifyContent: 'space-around',
                          alignItems: 'end',
                          textAlign: 'center',
                          flexWrap: 'wrap'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Maximize2 color='white' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            1.80M
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Image src={App} width='24px' height='24px' color='white' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            APP
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Radio color='white' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            RFID
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Wifi color='white' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            WIFI
                          </p>
                        </div>

                        <div
                          className='font-display sm:text-base text-xs text-white dark:text-white'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Image src={Charging} width='24px' height='24px' color='white' />
                          <p
                            className='text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px',
                            }}>
                            220V
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Activity color='white' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            60HZ
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Image src={Trash} width='24px' height='24px' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            CAPACIDAD <br />
                            400 BOTELLAS
                          </p>
                        </div>

                        {/* <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '15px'
                          }}
                        >
                          <Image src={Bin} width='24px' height='24px' color='white' />
                          <p
                            className='font-display sm:text-base text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '10px'
                            }}>
                            200 PLÁSTICO
                          </p>
                        </div> */}

                      </div>
                    </animated.div>
                  )}

                  {/* objetivo */}
                  {objetivo && (
                    <animated.div
                      className="font-display"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor:'red',
                        height: '100%',
                        opacity: textOpacity
                      }}
                    >
                      <h1
                        className='text-xl font-semibold mb-5 text-white dark:text-white'
                      >Objetivo</h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          justifyContent: 'center',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          className='text-white dark:text-white'
                          style={{
                            width: "100%"
                          }}>
                          IRBin busca ser un agente de cambio que mediante sus interacciones con los
                          usuarios, en especial familias con niños pequeños, logre fomentar comportamientos
                          responsables en el ámbito del reciclaje de forma lúdica y educativa.
                        </p>
                      </div>
                    </animated.div>
                  )}
                </animated.div>
              </>

            )
            :
            (
              <>
                {popup && (
                  <div
                    style={{
                      height: '100px',
                      backgroundColor: "transparent",
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      bottom: '45%',
                    }}
                    onClick={closeHandler}
                  >
                  </div>
                )
                }
                <animated.div style={{
                  width: '100%',
                  height: popupHeight,
                  background: "rgba(202, 217, 255, 0.15)",
                  position: 'absolute',
                  left: '0px',
                  bottom: '0px',
                  marginLeft: "auto",
                  marginRight: "auto",
                  backdropFilter: 'blur(20px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textShadow: '0px 3px 15px #212224',
                  borderTop: popup ? '2px solid rgba(202, 217, 255, 0.2' : (null)
                }}>
                  {/* como funciona */}
                  {funciona && (
                    <animated.div
                      className='text-white dark:text-white font-display'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        opacity: textOpacity,
                        width: '100%',
                        padding: '20px 0px',
                        textShadow: '0px 3px 15px #212224',
                      }}
                    >
                      <h1
                        className='text-xl font-semibold mb-10 text-white dark:text-white'
                      >¿Cómo funciona?</h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '50%',
                          justifyContent: 'space-around',
                          textAlign: 'center',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        <p className='mb-5 text-white dark:text-white' style={{
                          minWidth: '50%',
                        }}>
                          1. Recepciona los residuos
                        </p>
                        <p className='mb-5 text-white dark:text-white' style={{
                          minWidth: '50%',
                        }}>
                          2. Reconocimento con IA
                        </p>
                        <p className='mb-5 text-white dark:text-white' style={{
                          minWidth: '50%',
                        }}>
                          3. Segrega por tipo de residuo
                        </p>
                        <p className='mb-5 text-white dark:text-white' style={{
                          minWidth: '50%',
                        }}>
                          4. Control del nivel de llenado
                        </p>
                      </div>
                    </animated.div>
                  )}

                  {/* informacion */}
                  {informacion && (
                    <animated.div
                      className='text-white dark:text-white font-display'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor:'red',
                        height: '100%',
                        opacity: textOpacity,
                        width: '100%',
                        textShadow: '0px 3px 15px #212224'
                      }}
                    >
                      <h1
                        className='text-xl font-semibold mb-5 text-white dark:text-white'
                      >Información</h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          justifyContent: 'space-around',
                          alignItems: 'end',
                          textAlign: 'center',
                          flexWrap: 'wrap'
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Maximize2 />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            1.80M
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Image src={App} width='24px' height='24px' />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            APP
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Radio />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            RFID
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Wifi />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            WIFI
                          </p>
                        </div>

                        <div
                          className='font-display text-xs text-white dark:text-white'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Image src={Charging} width='24px' height='24px' />
                          <p style={{
                            width: "100%",
                            marginTop: '25px',
                          }}>
                            220V
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Activity />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            60HZ
                          </p>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Image src={Trash} width='24px' height='24px' />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            CAPACIDAD 400 BOTELLAS
                          </p>
                        </div>

                        {/* <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '20px',
                          }}
                        >
                          <Image src={Bin} width='24px' height='24px' />
                          <p
                            className='font-display text-xs text-white dark:text-white'
                            style={{
                              width: "100%",
                              marginTop: '25px'
                            }}>
                            200 PLÁSTICO
                          </p>
                        </div> */}

                      </div>
                    </animated.div>
                  )}

                  {/* objetivo */}
                  {objetivo && (
                    <animated.div
                      className='text-white dark:text-white font-display'
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor:'red',
                        height: '100%',
                        opacity: textOpacity,
                        textShadow: '0px 3px 15px #212224'
                      }}
                    >
                      <h1
                        className='text-xl font-semibold mb-5 text-white dark:text-white'
                      >Objetivo</h1>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          width: '80%',
                          justifyContent: 'center',
                          textAlign: 'center'
                        }}
                      >
                        <p
                          className='text-white dark:text-white'
                          style={{
                            width: "100%"
                          }}>
                          IRBin busca ser un agente de cambio que mediante sus interacciones con los
                          usuarios, en especial familias con niños pequeños, logre fomentar comportamientos
                          responsables en el ámbito del reciclaje de forma lúdica y educativa.
                        </p>
                      </div>
                    </animated.div>
                  )}
                </animated.div>
              </>
            )}

      </Media>


      {!popup && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: '40px',
            left: '40px',
            pointerEvents: 'none'
          }}
        >
          <div
            style={{
              height: '50px',
              width: '50px',
              background: '#ffffff55',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: "center",
              marginRight: '25px',
            }}
          >
            <img src='../../images/orbit.svg' style={{ height: '25px', width: '25px' }} />
            {/* <Move size={15} color='white' /> */}
          </div>
          <p className='text-xs dark:text-jacarta-200 text-jacarta-200'>ARRASTRAR PARA<br /> ROTAR EN 360°</p>
        </div>
      )}
    </div>
  )
}
useGLTF.preload("/irbin7-transformed.glb");
useGLTF.preload("/ground3.glb");

