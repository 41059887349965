import Link from "next/link";
import React, { useState } from "react";
import News_item from "../../components/blog/news_item";
import { news_data } from "../../data/news_data";

const Blog = () => {
  const [data, setdata] = useState(news_data.slice(0, 6));

  return (
    <>
      {/* <!-- Blog --> */}
      <section className="relative pt-[5.5rem] lg:pt-8 pb-28 dark:bg-jacarta-700 bg-jacarta-700">
        <div className="pb-16 md:py-24">

          <div className="container">

            <h1 className="text-4xl text-white font-display dark:text-white text-center">
              ¿Cómo ser parte?
						</h1>
            <p className='dark:text-jacarta-200 text-jacarta-200 text-lg text-center mt-6 mb-20'>Únete a uno de nuestros modelos de alianza y lleva tu marca a otro nivel</p>
            <News_item data={data} />

          </div>
        </div>
      </section>
      {/* <!-- end blog --> */}
    </>
  );
};

export default Blog;
