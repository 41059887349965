const news_data = [
	{
		id: '0',
		text: 'Impulsa tu marca verde con un plan exclusivo',
		title: 'Activación de marca',
		image: '/images/blog/irbin-1.png',
		date: '5 Feb',
		time: '3 min read',
		plan: 'MODELO 1'
	},
	{
		id: '1',
		text: 'Potencia tu impacto en la comunidad con un plan permanente',
		title: 'Pauta permanente',
		image: '/images/blog/irbin-2.png',
		date: '5 Feb',
		time: '3 min read',
		plan: 'MODELO 2'
	},
	// {
	// 	id: '2',
	// 	text: 'Impulsa tu marca ecofriendly',
	// 	title: 'Premium',
	// 	image: '/images/blog/irbin-3.png',
	// 	date: '22 Feb',
	// 	time: '3 min read',
	// 	plan: 'MODELO 3'
	// },
];

const single_news_data = [
	{
		id: 'post_1',
		text: 'Since we launched Tezos at the end of 2021, many awesome creators...',
		title: 'Mint your own Tezos collections',
		image: '/images/blog/post_1.jpg',
		date: '5 Feb',
		time: '3 min read',
		subImages: ['/images/blog/gallery_1.jpg', '/images/blog/gallery_2.jpg'],
		authorImage: '/images/blog/author_large.jpg',
		authorName: 'Deothemes',
	},
	{
		id: 'post_2',
		text: 'Since we launched Tezos at the end of 2021, many awesome creators...',
		title: 'Mint your own Tezos collections',
		image: '/images/blog/post_2.jpg',
		date: '5 Feb',
		time: '3 min read',
		subImages: ['/images/blog/gallery_1.jpg', '/images/blog/gallery_2.jpg'],
		authorImage: '/images/blog/author_large.jpg',
		authorName: 'Deothemes',
	},
	{
		id: 'post_3',
		text: 'Since we launched Tezos at the end of 2021, many awesome creators...',
		title: 'List your collection for secondary sales',
		image: '/images/blog/post_3.jpg',
		date: '22 Feb',
		time: '3 min read',
		subImages: ['/images/blog/gallery_1.jpg', '/images/blog/gallery_2.jpg'],
		authorImage: '/images/blog/author_large.jpg',
		authorName: 'Deothemes',
	},
	{
		id: 'post_4',
		text: 'Since we launched Tezos at the end of 2021, many awesome creators...',
		title: 'The biggest moves in NFTs, Bitcoin, crypto rules',
		image: '/images/blog/post_4.jpg',
		date: '18 Jan',
		time: '3 min read',
		subImages: ['/images/blog/gallery_1.jpg', '/images/blog/gallery_2.jpg'],
		authorImage: '/images/blog/author_large.jpg',
		authorName: 'Deothemes',
	},
	{
		id: 'post_5',
		text: 'Since we launched Tezos at the end of 2021, many awesome creators...',
		title: "Incredible Amount of Developer Energy' in Web3",
		image: '/images/blog/post_5.jpg',
		date: '15 Jan',
		time: '3 min read',
		subImages: ['/images/blog/gallery_1.jpg', '/images/blog/gallery_2.jpg'],
		authorImage: '/images/blog/author_large.jpg',
		authorName: 'Deothemes',
	},
	{
		id: 'post_6',
		text: 'Since we launched Tezos at the end of 2021, many awesome creators...',
		title: "Inflation is up, it matters: High prices plague Biden's",
		image: '/images/blog/post_6.jpg',
		date: '23 Dec',
		time: '3 min read',
		subImages: ['/images/blog/gallery_1.jpg', '/images/blog/gallery_2.jpg'],
		authorImage: '/images/blog/author_large.jpg',
		authorName: 'Deothemes',
	},
];

export { news_data, single_news_data };
