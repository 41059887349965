import { useState } from "react";
import FaqAccordion from "./accoedion";
import Link from "next/link";

const Faq = () => {

  const [videoModal, setvideoModal] = useState(false);
  const [url, setUrl] = useState(null);

  return (
    <div>
      {/* <!-- FAQ --> */}
      <section className="relative py-24 dark:bg-jacarta-900 bg-jacarta-900">
        <div className="container">
          <div className="justify-between lg:flex lg:space-x-20">
            <div className="lg:w-[45%]">
              <h2 className="mb-6 font-display text-3xl font-medium text-white dark:text-white">
                ¿Cómo funciona?
              </h2>
              <p className="mb-12 text-lg dark:text-jacarta-300 text-jacarta-300">
                IRBin ayuda a segregar residuos otorgando beneficios a las personas que reciclan. De esta manera fomenta el hábito de reciclaje para así lograr una ciudad más sostenible.
              </p>

              <FaqAccordion />

              {/* <a href="/contacto" >
              </a> */}
              <Link href="/contacto">
                <a className="dark:text-jacarta-300 text-jacarta-300  mb-8 text-md leading-normal dark:hover:text-accent-dark hover:text-accent-dark group ease-out duration-200 ">
                  ¿Quieres desarrollar un proyecto?<span className="dark:text-accent text-accent dark:group-hover:text-accent-dark group-hover:text-accent-dark ease-out duration-200"> Conversemos</span>
                </a>
              </Link>
            </div>
            <div className="lg:w-[55%]">
              <div className="mt-12 md:flex md:space-x-8 lg:justify-end">
                <div className="relative mb-8 max-w-[13.125rem] self-end rounded-2.5xl bg-jacarta-900 p-8 border dark:border-accent border-accent">
                  <div>
                    <span className="mb-4 block font-display text-base text-white">
                      Botellas reciclables
                    </span>
                    <span className="mb-4 block font-display text-4xl text-accent">
                      250.000+
                    </span>
                    <span className="block text-base text-white">
                      Recuperadas hasta la fecha
                    </span>
                  </div>
                </div>
                <figure className="flex justify-center items-center mb-8 ">
                  <button
                    onClick={() => { setvideoModal(true); setUrl("https://www.youtube.com/embed/IWMlzZYu3ss"); }}
                    className="js-video-modal-trigger absolute flex h-16 w-16  items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90 ease-out duration-300"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-8 w-8 fill-white"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" />
                    </svg>
                  </button>
                  <img
                    src="/images/blog/irbin-2.png"
                    alt="irbin"
                    className="inline-block rounded-2.5xl"
                  />
                </figure>
              </div>
              <div className="relative">

                <div className="relative mx-auto max-w-xs self-start rounded-2.5xl bg-jacarta-900 p-8 dark:bg-jacarta-900 p-8 border dark:border-accent border-accent">
                  <div className="absolute right-8 top-8 rounded-full bg-accent p-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="fill-black"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M4.406 14.523l3.402-3.402 2.828 2.829 3.157-3.157L12 9h5v5l-1.793-1.793-4.571 4.571-2.828-2.828-2.475 2.474a8 8 0 1 0-.927-1.9zm-1.538 1.558l-.01-.01.004-.004A9.965 9.965 0 0 1 2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10c-4.07 0-7.57-2.43-9.132-5.919z" />
                    </svg>
                  </div>

                  <div className="text-left">
                    <span className="mb-4 block font-display text-base text-white">
                      Residuos reciclables
                    </span>
                    <span className="mb-4 block font-display text-4xl text-accent">
                      10Tn+
                    </span>
                    <span className="block text-base text-white">
                      Recuperadas hasta la fecha
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end faq --> */}

      {/* <!-- YouTube Video Modal --> */}
      <div
        onClick={() => {
          setvideoModal(false);
          setUrl(null);
        }}
        className={
          videoModal
            ? "modal lightbox fade show block dark:bg-jacarta-900/90 bg-jacarta-900/90 cursor-default"
            : "modal lightbox fade"
        }
      >
        <div className="modal-dialog modal-dialog-centered modal-xl w-full ">
          <div className="modal-content border-0 bg-transparent ">
            <div className="modal-body p-0 relative ">
              <button
                onClick={() => {
                  setvideoModal(false);
                  setUrl(null);
                }}
                type="button"
                className="btn-close position-absolute top-0 end-0 p-3 z-10"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  fill="#fff"
                  className="h-6 w-6"
                >
                  <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                </svg>
              </button>
              <div
                id="lightboxCarousel-d7ewe4ig"
                className="lightbox-carousel carousel"
              >
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="position-absolute top-50 start-50 translate-middle text-white">
                      <div
                        className="spinner-border"
                        role="status"
                      ></div>
                    </div>
                    <div className="ratio ratio-16x9">
                      <iframe
                        src={url}
                        title="YouTube video player"
                        allow="accelerometer clipboard-write encrypted-media gyroscope picture-in-picture autoplay"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
