import React from 'react';

export const case_studies_data = [
	{
		id: 1,
		img: '/images/case-studies/case_study_1.jpg',
		title: 'EL PRIMER ROBOT SOCIAL CON IA PARA EL RECICLAJE EN PERÚ',
		href: 'irbin',
	},
];
